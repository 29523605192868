<template>
    <div class="reviews w-full" id="reviews">
        <div class="centered-content-container mx-auto">
            <div class="reviews-wrap block-wrap w-full">
                <h2 class="mx-auto text-center">Відгуки наших клієнтів</h2>
                <div class="slider-wrap w-full">
                    <div class="reviews-button prev flex justify-center items-center" @click="prevSlide">
                        <img src="../assets/img/right-arrow.svg" alt="">
                    </div>
                    <VueSlickCarousel v-bind="settings" ref="reviews-slider" class="reviews-slider">
                        <div class="slider-item flex h-full" v-for="item in reviews" :key="item.id">
                            <div class="slider-item-inner flex flex-col">
                                <p v-if="item.content.length > 145" class="italic mb-5 font-medium long">{{contentSplit(item)}} <span @click="seeText(item)" class="text-next">Читати далі</span></p>
                                <p v-else class="italic mb-5 font-medium">{{ item.content }}</p>
<!--                                <p v-else class="italic mb-5 font-medium" :class="{'long': item.content.length > 145}">{{ item.content }} <span v-if="item.content.length > 145" @click="seeText(item)" class="text-next">Читати далі</span></p>-->
                                <span class="text-xs sm:text-sm text-blue font-medium">{{ item.info }}</span>
                            </div>
                        </div>
                    </VueSlickCarousel>
                    <div class="reviews-button next flex justify-center items-center" @click="nextSlide">
                        <img src="../assets/img/left-arrow.svg" alt="">
                    </div>
                </div>
            </div>
            <Popup :review="review" :isPopUpOpened ="isPopUpOpened" @closeModal='closeModal'></Popup>
        </div>
    </div>
</template>

<script>
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import Popup from "./popupReview"
    export default {
        name: "reviews-slider",
        components: { VueSlickCarousel, Popup },
        data() {
            return {
                reviews: [
                    {
                        id: 1,
                        info: 'Михайло Гугляк, Переяслав-Хмельницький район, Київська область, 10 000 голів.',
                        content: 'На 80% успіх відтворення  - у здоровому та активному кнурі. Завдяки SPERMATEK® наразі маємо запліднюваність на рівні 92%. Ми задоволені!'
                    },
                    {
                        id: 2,
                        info: 'Ігор Стадніченко, Київська область, Сквирський район, свинокомплекс, 4 кнура',
                        content: 'Використовую цей продукт вже 2-ий рік. Маю 4 кнура, вагою від 280 кг до 350 кг. Наявність еякуляту збільшилася в 2 рази,' +
                            ' сперма стала густішою. До використання продукту отримував від 1 кнура 240 г, а після використання продукту отримую 450 г.' +
                            ' Повністю уникнув проблем з кінцівками: немає кульгавості, кнурі тепер можуть довго стояти на 2-х ногах.' +
                            ' Є зміни в кількості спожитого корму на добу: раніше кнурі їли до 5 кг, тепер з використанням SPERMATEK® з’їдають до 3 кг.' +
                            ' За рахунок забезпечення здоров’я плідника ми змогли підвищити та утримуємо багатоплідність свиноматок на рівні 12 живонароджених поросят.'
                    },
                    {
                        id: 3,
                        info: 'Світлана, Бахмацький район, Чернігівська область, ТОВ «Чернігівська м’ясна компанія», 1000 свиноматок, 10 кнурів',
                        content: 'Вже майже два роки користуємося продуктом (з лютого 2019 року).  За рахунок використання SPERMATEK® збільшилася концентрація сперми,' +
                            ' а також зменшився відсоток аглюцинації, цитоплазматичної каплі сперміїв. Тобто, якість сперми значно покращилася.' +
                            ' Системне застосування продукту дало можливість отримати осіменіння 91% і вище та збільшити кількість живонароджених поросят в ' +
                            '1 гнізді на 2-ві голови. '
                    },
                    {
                        id: 4,
                        info: 'Олександр Коновал, Білоцерківський район, Київська область, Приватний підприємець ',
                        content: 'При утриманні кнурів часто виникали патології дистального відділу кінцівок (кульгавість), ' +
                            'а також спостерігав зниження рухливості сперміїв (нижче 70%). Почав цікавитися продуктами для кнурів. ' +
                            'Колеги порадили спробувати спеціальну добавку для кнурів SPERMATEK®.' +
                            ' Почав застосовувати, що дало можливість вирішити вище згадані проблеми.' +
                            ' Рухливість сперміїв покращилася до 90-100%. Користуюся відносно недовго, але я задоволений.'
                    },
                ],
                review: {},
                isPopUpOpened: false,
                settings: {
                    "arrows": false,
                    "focusOnSelect": true,
                    // "centerMode": true,
                    "infinite": true,
                    // "autoplay": true,
                    "speed": 800,
                    // "autoplaySpeed": 2000,
                    "slidesToShow": 2,
                    "slidesToScroll": 1,
                    "touchThreshold": 10,
                    "responsive": [
                        {
                            "breakpoint": 640,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                            }
                        }
                    ]
                },
            }
        },
        methods: {
            nextSlide() {
                this.$refs['reviews-slider'].next()
            },
            prevSlide() {
                this.$refs['reviews-slider'].prev()
            },
            seeText(item) {
                console.log(item.content.length)
                // this.isPopUpOpened = false
                this.review = item
                this.isPopUpOpened = true
                // console.log(item.content.length)
                document.body.classList.add('stop-scrolling')
            },
            contentSplit(item) {
                let str = item.content.substr(0, 130)
                let index = str.lastIndexOf(' ')
                return str.substr(0, index) + '...'
            },
            closeModal() {
                this.isPopUpOpened = false
            }
        }
    }
</script>

<style scoped>
    .reviews {
        position: relative;
    }

    .reviews:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0, #142F5E 0%, rgba(20,47,94,0) 100%);
        pointer-events: none;
        /*opacity: 5% !important;*/
    }

    .reviews-wrap {
        position: relative;
    }

    .reviews-wrap:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 908px;
        height: 504px;
        background: url("../assets/img/reviews-drop-img.svg") center/100% no-repeat;
        /*opacity: 5% !important;*/
    }


    .slider-wrap {
        padding: 0 85px;
        position: relative;
        z-index: 1;
    }

    .slider-wrap .reviews-slider {
        display: flex!important;
    }

    .reviews-wrap .slider-item-inner {
        padding: 50px;
        margin: 0 15px;
        z-index: 5;
        background-color: #ffffff;
    }

    /*.reviews-wrap .slider-item-inner p.long {*/
    /*    overflow: hidden;*/
    /*    text-overflow: ellipsis;*/
    /*    display: -moz-box;*/
    /*    -moz-box-orient: vertical;*/
    /*    display: -webkit-box;*/
    /*    -webkit-box-orient: vertical;*/
    /*    box-orient: vertical;*/
    /*    line-clamp: 3.5;*/
    /*    -webkit-line-clamp: 3.5;*/
    /*    max-width: 145ch;*/
    /*}*/

    .reviews-wrap .slider-item-inner span {
        max-width: 320px;
    }


    .reviews-wrap .reviews-button {
        @apply bg-pink;
        position: absolute;
        box-shadow: 0 20px 40px 0 rgba(216,38,126,0.3);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        cursor: pointer;
        z-index: 4;
    }

    .reviews-wrap .reviews-button.prev {
        left: 0;
        top: calc(50% - 25px);
    }

    .reviews-wrap .reviews-button.next {
        right: 0;
        top: calc(50% - 25px);
    }

    .text-next {
        @apply text-pink underline;
        white-space: nowrap
    }

    @media(max-width: 1024px) {
        .reviews-wrap .slider-item-inner {
            padding: 2rem;
        }
    }

    @media(max-width: 950px) {
        .reviews:after {
            content: '';
            position: absolute;
            top: -5%;
            left: 0;
        }

        .reviews-wrap .slider-item-inner {
            padding: 1.5rem;
        }

        .slider-wrap {
            padding: 10px;
        }

        .reviews-wrap .reviews-button {
            width: 40px;
            height: 40px;
        }

        .reviews-wrap .reviews-button.prev {
            top: calc(50% - 20px);
        }

        .reviews-wrap .reviews-button.next {
            top: calc(50% - 20px);
        }
    }

    @media(max-width: 640px) {

        .reviews-wrap .slider-item-inner {
            padding: 1rem;
        }

        .slider-wrap {
            padding: 0;
        }
        .reviews-wrap.block-wrap {
            padding-top: 0;
        }

        .reviews-wrap .reviews-button {
            width: 30px;
            height: 30px;
        }

        .reviews-wrap .reviews-button img {
            width: 50%;
            height: 50%;
        }

        .reviews-wrap .reviews-button.prev {
            top: calc(50% - 15px);
        }

        .reviews-wrap .reviews-button.next {
            top: calc(50% - 15px);
        }
    }

    /*.slider-item-inner p.long {*/
    /*    white-space: nowrap; !* Запрещаем перенос строк *!*/
    /*    overflow: hidden; !* Обрезаем все, что не помещается в область *!*/
    /*    text-overflow: ellipsis; !* Добавляем многоточие *!*/
    /*    line-clamp: 3;*/
    /*}*/
    /*.reviews-wrap .reviews-button.prev {*/
    /*    background: url("../assets/img/btn-slide-back.svg") center/contain no-repeat;*/
    /*}*/
    /*.reviews-wrap .reviews-button.next {*/
    /*    background: url("../assets/img/btn-slide-prev.svg") center/contain no-repeat;*/
    /*}*/


    /*.slick-slide {*/
    /*    padding:  0 15px;*/
    /*}*/

    /*.slider-item {*/
    /*    height: calc(291px + 5.2vw);*/
    /*    width: 100%;*/
    /*    padding: 0 5%;*/
    /*}*/
</style>