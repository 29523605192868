<template>
        <div class="popup-block flex items-center" v-show="isPopUpOpened">
            <div class="container-popup">
                <div class="services-popup-wrap">
                    <div class="scroll-wrap w-full h-full">
                        <div class="close-icon" @click="closeModal"></div>
                        <p class="italic mb-5 font-medium">{{ review.content }}</p>
                        <span class="text-xs sm:text-sm text-blue font-medium">{{ review.info }}</span>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        name: "popupReview",
        props: ['review', 'isPopUpOpened'],
        // data () {
        //     return {
        //         isPopUpOpened: false,
        //     }
        // },
        // watch : {
        //     state: function (val) {
        //         this.isPopUpOpened = val
        //     }
        // },
        methods: {
            closeModal() {
                document.body.classList.remove('stop-scrolling')
                this.$emit('closeModal')

            }
        }

    }
</script>

<style scoped>

    ::-webkit-scrollbar  {
        width: 4px!important;
    }

    ::-webkit-scrollbar-track {
        background-color: #D8267E!important;
    }

    ::-webkit-scrollbar-track-piece {
        background-color: white!important;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #D8267E!important;
        height:           50px!important;
        border-radius:    3px!important;
    }

    ::-webkit-scrollbar-corner {
        background-color: #D8267E!important;
    }

    ::-webkit-resizer {
        background-color: #D8267E!important;
    }

    .popup-block {
        position: fixed;
        background-color: rgba(85, 92, 108, 0.3);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 20;
    }

    .scroll-wrap {
        max-height: 50vh;
        overflow-y:auto;
        padding-right: 1.25rem;
    }

    .container-popup {
        @apply p-5;
        display: flex;
        align-items:center;
        justify-content:center;
        width: 100%;
        height: auto;
    }

    .services-popup-wrap {
        padding: 2.5rem 1.5rem 1.5rem;
        position: relative;
        max-width: 525px;
        width: 100%;
        background-color: #ffffff;
        background-size: cover;
        border-radius: 1rem;
    }




    /*@media (max-height: 414px) {*/
    /*    !*.services-popup-wrap {*!*/
    /*    !*    max-height: 95vh;*!*/
    /*    !*    height: 100%;*!*/
    /*    !*}*!*/
    /*}*/


    /*@media (max-width: 640px) {*/
    /*    .services-popup-wrap {*/
    /*        max-height: 95vh;*/
    /*        height: 100%;*/
    /*    }*/

    /*    .landing-button p {*/
    /*        font-size: 0.75rem;*/
    /*    }*/
    /*}*/


    .item-title {
        @apply font-normal
    }

    .item-description ul li span {
        @apply mr-10
    }

    .close-icon {
        content: '';
        /*background-color: #142F5E;*/
        background: url("../assets/img/cross-close-svgrepo-com.svg") center/contain no-repeat;
        position: absolute;
        color: #142F5E;
        width: 20px;
        height: 20px;
        top: 15px;
        right: 15px;
        opacity: 1;
        cursor: pointer;
    }



</style>
