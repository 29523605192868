<template>
    <div class="block-form text-white relative" id="form">
        <div class="centered-content-container  mx-auto block-wrap">
<!--            <div class="form-wrapper block-wrap relative">-->
<!--                <div class="title-wrap mb-5 mx-auto text-center text-blue">-->
<!--                    <h1>Замовити SPERMATEK®</h1>-->
<!--                </div>-->
<!--                <form class="form" @submit.prevent="formAction">-->
<!--                    <div class="form-item">-->
<!--                        <input  v-model.trim="formData.author" name="author" type="text" placeholder="Ім’я" required>-->
<!--                    </div>-->
<!--                    <div class="form-item">-->
<!--                        <input v-model="formData.phone" type="text" name="phone" placeholder="Телефон">-->
<!--                    </div>-->
<!--                    <div class="form-item">-->
<!--                        <input v-model="formData.masa" type="text" name="masa" placeholder="Бажаю замовити">-->
<!--                    </div>-->
<!--                    <div class="button" type="submit">-->
<!--                        Замовити SPERMATEK®-->
<!--                    </div>-->
<!--                </form>-->
<!--            </div>-->
            <div ref="bitrix" class="bitrix">
            </div>
        </div>
<!--        <transition name="fade">-->
<!--            <div v-if="show" class="message-block absolute top-0 right-0">-->
<!--                <div class="message-block-wrap">-->
<!--                    <i class="far fa-check-circle"></i>-->
<!--                    <h4>Дякую</h4>-->
<!--                    <p>Ваше повідомлення успішно відправлене</p>-->
<!--                </div>-->
<!--            </div>-->
<!--        </transition>-->
    </div>
</template>

<script>
    // import axios from 'axios'

    export default {
        name: "form-block",
        data () {
            return {
                popup: null,
                formData: {
                    author: '',
                    phone: '',
                    masa: '',
                }
            }
        },
        mounted() {
            let linkedinDopScript = document.createElement('script')
            linkedinDopScript.setAttribute('id', 'bx24_form_inline');
            linkedinDopScript.setAttribute('data-skip-moving', 'true');
            linkedinDopScript.innerText = `(function(w,d,u,b){w['Bitrix24FormObject']=b;w[b] = w[b] || function(){arguments[0].ref=u;
                (w[b].forms=w[b].forms||[]).push(arguments[0])};
                if(w[b]['forms']) return;
                var s=d.createElement('script');s.async=1;s.src=u+'?'+(1*new Date());
                var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
            })(window,document,'https://tekro-ua.bitrix24.ua/bitrix/js/crm/form_loader.js','b24form');

            b24form({"id":"14","lang":"ru","sec":"xdt0do","type":"inline"})`
            this.$refs.bitrix.appendChild(linkedinDopScript)
            console.log(window.frames.length)
            // this.popup = document.getElementById('RESULT_MESSAGE_CONTAINER') ? true : false
            // document.querySelector('button').innerText = 'Замовити SPERMATEK®'
        }
        // methods: {
        //     async formAction () {
        //         console.log(this.formData.formName, this.formData.telephone, this.formData.message )
        //         // this.$v.$touch()
        //         this.show = true;
        //         // const str = JSON.stringify(this.formData);
        //
        //         const urlParams = new URLSearchParams(this.formData);
        //
        //         axios.get('http://lion.upbeat.com.ua/sendmail?' + urlParams.toString())
        //             .then((response) => {
        //                 console.log(response);
        //             })
        //             .catch((error) => {
        //                 console.log(error);
        //             });
        //
        //
        //
        //         this.formData.telephone = '';
        //         this.formData.formName = '';
        //         this.formData.message = '';
        //
        //         setTimeout(() => this.show = false, 1500)
        //     },
        // }
    }

</script>

<style scoped>
    .block-form {
        background-color: #DAEEF5;
    }

    .form-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .form-wrapper h1 {
        text-align: center;
        /*color: white;*/
        max-width: 540px;
    }


    .form {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
       max-width: 570px;
    }

    .form .button {
        margin-top: 15px!important;
    }

    .form-item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px;
        width: 100%;
        min-width: 200px;
    }

    .form-item:nth-child(-n+2) {
        flex: 0 1 calc((100% / 2) - 30px);
    }

    .form-item input {
        @apply text-blue bg-white;
        width: 100%;
        height: 60px;
        padding: 0 30px;
        border-radius: 30px;
        /*background-color: rgba(0, 0, 0, 0.2);*/
    }

    .form-item input::placeholder {
        @apply font-medium text-blue;
        /*color: rgba(255, 255, 255, 0.5);*/
        font-size: 18px;
    }

    /*.block-form .block-wrap {*/
    /*    position: relative;*/
    /*}*/

    /*.block-form .block-wrap:after {*/
    /*    position: absolute;*/
    /*    background: url("../assets/img/form-img.png") center/contain no-repeat ;*/
    /*    content: '';*/
    /*    width: 20%;*/
    /*    height: 50%;*/
    /*    left: 0;*/
    /*    bottom: 10%;*/
    /*}*/

    .bitrix {
        position: relative;
    }

    .bitrix:after {
        position: absolute;
        background: url("../assets/img/form-img.png") center/contain no-repeat ;
        content: '';
        width: 20%;
        height: 50%;
        left: 0;
        bottom: 10%;
    }

    /*.bitrix-img-block {*/
    /*    position: absolute;*/
    /*    content: '';*/
    /*    width: 20%;*/
    /*    height: 20%;*/
    /*    left: 0;*/
    /*    top: 50%;*/
    /*}*/
    
    /*.crm-webform-header {*/
    /*    color: darkred!important;*/
    /*}*/



    /*.message {*/
    /*    @apply p-5 rounded bg-white;*/

    /*}*/
    /*.message-block {*/
    /*    @apply p-5 flex flex-col items-center justify-center rounded-lg w-full h-full;*/
    /*    background-color: rgba(0, 0, 0, 0.7);*/
    /*}*/

    /*.message-block-wrap {*/
    /*    @apply p-5 flex flex-col items-center justify-center rounded-lg;*/
    /*    width: calc(200px + 5.2vw);*/
    /*    !*background: url("../assets/img/header-bg-2.png") no-repeat top;*!*/
    /*    background-size: 100% 100%;*/
    /*    border: 2px solid rgba(255, 255, 255, 0.8);*/
    /*}*/

    /*.message-block-wrap h4 {*/
    /*    @apply text-white mb-5;*/
    /*}*/

    /*.message-block-wrap .fa-check-circle {*/
    /*    @apply text-white mb-5;*/
    /*    font-size: calc(40px + 0.5vw);*/
    /*}*/

    /*.message-block-wrap p {*/
    /*    text-align: center;*/
    /*}*/

    /*.fade-enter-active, .fade-leave-active {*/
    /*    transition: opacity .5s;*/
    /*}*/
    /*.fade-enter, .fade-leave-to {*/
    /*    opacity: 0;*/
    /*}*/

    @media(max-width:950px) {
        .bitrix {
            padding-bottom: 10%;
        }

        .bitrix:after {
            bottom: 0;
            width: 30%;
            height: 30%;
        }
    }

    @media(max-width:640px) {
        .bitrix {
            padding-bottom: 40%;
        }
        .bitrix:after {
            bottom: 0;
            left: 30%;
            width: 40%;
            height: 30%;
        }
    }

    @media(max-width:500px) {
        .bitrix {
            padding-bottom: 130px;
        }
        .bitrix:after {
            bottom: 0;
            left: calc(50% - 75px);
            width: 150px;
            height: 150px;
        }
    }



</style>