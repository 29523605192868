<template>
    <div class="action w-full" id="action">
        <div class="centered-content-container flex flex-col mx-auto">
            <div class="action-wrap block-wrap w-full flex flex-col">
                <div class="title-block mb-10 mx-auto text-center">
                    <h1>Ще сумніваєтесь?</h1>
                    <p class="text-content mx-auto">Науково доведено: збільшення рухливості сперміїв лише на 1% дає + 0,14 поросят на опорос.
                        Спробуйте SPERMATEK® та відчуйте різницю!</p>
                </div>
                <div class="action-item-wrap" id="action-block">
                    <div class="action-item items-start pr-0 sm:pr-10" id="action-mob">
                        <h2>Як працює SPERMATEK®?</h2>
                        <ol>
                            <li><span>Винятковий склад,</span> що відповідає потребам кнурів. Вам не потрібно дбати про добавки або інші компоненти раціону.</li>
                            <li><span>Натуральність та безпечність компонентів SPERMATEK®</span> дозволяють отримати якісну та здорову продукцію. Не викликає «звикання»!</li>
                            <li><span>Легко додавати та змішувати</span> - 25% до загального раціону.</li>
                            <li><span>Через місяць зможете переконатися</span> в суттєвій трансформації показників відтворення.</li>
                            <li><span>Стабільно якісна спермопродукція</span> – постійно високі показники відтворення та рентабельності бізнесу.</li>
                        </ol>
                    </div>
                    <div class="action-item p-5 sm:p-20 text-center flex flex-col justify-center items-center">
                        <div class="action-img_wrap">
                            <img class="action-img" src="../assets/img/advantage-pig.webp" alt="">
                        </div>
                        <p class="mb-5 text-blue font-medium">Скористайтеся можливістю - замовте SPERMATEK® та отримайте
                            комплексний аудит системи відтворення у господарстві безкоштовно*!</p>
                        <div class="button mb-5" @click="scrollTo('#form')">
                            Замовити дзвінок
                        </div>
                        <span class="text-xs sm:text-sm text-blue-light">* деталі дізнавайтеся у менеджера-консультанта!</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "action",
        methods: {
            scrollTo (hash) {
                const top = document.querySelector(hash).offsetTop - 80
                window.scrollTo({ top: top, behavior: 'smooth' });
            }
        }
    }
</script>

<style scoped>

    .action-wrap:before {
        content: '';
        position: absolute;
        width: 1289px;
        height: 414px;
        top: 0;
        left: -10%;
        background: url("../assets/img/action-bg-img.svg") center/100% no-repeat;
        pointer-events: none;
    }

    .title-block {
        z-index: 2;
    }

    .action-wrap {
        position: relative;
        z-index: 1;
    }

    .action-wrap h1 {
        color: #D8267E
    }

    .action-wrap .title-block p {
        max-width: 630px;
        font-size: 24px;
        font-style: italic;
        line-height: 36px;
    }

    .action-item-wrap {
        @apply flex flex-wrap relative;
        margin: -15px;
        padding-top: 180px;
    }

    .action-item-wrap:before {
        content: '';
        position: absolute;
        width: 58px;
        height: 60px;
        top: 5%;
        left: 45%;
        background: url("../assets/img/action-drop-center.svg") center/100% no-repeat;
        pointer-events: none;
    }


    .action-item {
        display: flex;
        flex: 0 1 calc((100%/2) - 30px);
        flex-direction: column;
        margin: 15px;
    }

    .action-item:nth-child(2) {
        position: relative;
        background-color: #DAEEF5;
    }

    .action-item:nth-child(2):before {
        content: '';
        position: absolute;
        width: 35%;
        height: 35%;
        bottom: -10%;
        left: -10%;
        background: url("../assets/img/advantage-product.webp") center/contain no-repeat;
    }

    .action-item:nth-child(2) p,
    .action-item:nth-child(2) span {
        /*color: #142F5E;*/
    }

    /*.advantage-item:nth-child(2) span {*/
    /*    opacity: 50%;*/
    /*}*/

    .action-item ol {
        counter-reset: item;
    }

    .action-item ol li {
        @apply pl-5;
        display: block;
        position: relative;
        line-height: 30px;
    }

    .action-item ol li:not(:last-child) {
        @apply mb-5;

    }

    .action-item ol li:before {
        position: absolute;
        left: 0;
        content: counter(item) ". ";
        counter-increment: item;
        color: #D8267E;
        font-weight: 500;
    }

    .action-item ol li span {
        @apply text-blue
    }

    .action-img_wrap {
        position: absolute;
        bottom: 88%;
        right: 5%;
        max-width: 50%;
    }

    @media(max-width:1020px) {
        .action-item-wrap {
            margin: 0;
        }

        .action-item {
            display: flex;
            flex: 0 1 100%;
            flex-direction: column;
            margin: 0;
        }

        .action-item h2 {
            margin-top: 60px;
        }

        .action-item:nth-child(2) {
            order: 1;
        }

        .action-item:nth-child(1) {
            order: 2;
        }
    }

    @media(max-width:1024px) {
        .action-wrap:before {
            top: -2%;
            left: 0;
        }
    }

    @media(max-width:640px) {
        .action-wrap:before {
            width: 600px;
            height: 207px;
        }

        .action-item-wrap:before {
            content: '';
            position: absolute;
            width: 29px;
            height: 30px;
            top: 5%;
            left: 45%;
            background: url("../assets/img/action-drop-center.svg") center/100% no-repeat;
            pointer-events: none;
        }

        .app h1 {
            font-size: 36px;
            line-height: 42px;
            text-align: center;
        }

        .action-wrap .title-block p {
            font-size: 18px;
        }

        .action-item-wrap {
            padding-top:100px;
        }

        .action-img_wrap {
            max-width: 32%;
            position: absolute;
            bottom: 88%;
            right: 5%;
        }

        .action-item:nth-child(2):before {
            width: 40%;
            height: 40%;
            bottom: -10%;
            left: -10%;
        }
    }

    @media(max-width:450px) {
        .action-item:nth-child(2):before {
            width: 30%;
            height: 30%;
            bottom: -10%;
            left: -7%;
        }

        .action-img_wrap {
            max-width: 38%;
            position: absolute;
            bottom: 88%;
            right: 5%;
        }
    }

    @media(max-width:350px) {
        .action-item:nth-child(2):before {
            width: 25%;
            height: 25%;
            bottom: -10%;
            left: -5%;
        }
    }

    @media(max-height: 400px) {
        .action-img_wrap {
            max-width: 30%;
            position: absolute;
            bottom: 88%;
            right: 5%;
        }
    }

</style>