<template>
    <div class="ration-block">
        <div class="ration w-full">
            <div class="centered-content-container mx-auto relative">
                <img class="ration-img-drop" src="../assets/img/ration-top.svg" alt="">
                <img class="ration-img-drop" src="../assets/img/ration-center.svg" alt="">
                <img class="ration-img-drop" src="../assets/img/ration-left.svg" alt="">
                <img class="ration-img-drop" src="../assets/img/ration-right.svg" alt="">
                <div class="ration-wrap block-wrap flex flex-col items-start justify-center w-full">
                    <div class="ration-title">
                        <h2>Кнурі потребують особливого, створеного лише для них раціону</h2>
                    </div>
                    <div class="ration-description w-full flex flex-wrap">
                        <div class="ration-text">
                            <p>При згодовуванні кнурам раціону для лактуючих або супорісних свиноматок, можуть виникають наступні проблеми:</p>
                            <ul>
                                <li>Ожиріння</li>
                                <li>Хвороби кінцівок</li>
                                <li>Загальна слабкість</li>
                                <li>Знижене лібідо</li>
                            </ul>
                            <h4 class="font-medium">Як результат – низька якість спермопродукції та незадовільна заплідненість свиноматок.</h4>
                        </div>
                        <div class="ration-img-wrap flex flex-col justify-end">
                            <div class="speech-block">
                               <p class="speech">Кнур = 50% генетичного потенціалу ферми</p>
                            </div>
                            <img class="ration-img" src="../assets/img/ration-pig.webp" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div class="special-ration w-full" id="advantage">
                <div class="centered-content-container mx-auto">
                    <div class="special-ration-wrap block-wrap flex flex-col items-center justify-center w-full">
                        <div class="special-ration-title-block flex flex-col items-center justify-center text-center mx-auto">
                            <h2 class="mb-5">Кнурам необхідний спеціальний раціон!</h2>
                            <p class="text-content"> SPERMATEK® – це винятковий продукт для кнурів, що збагачений сумішшю біологічно активних рослинних екстрактів та
                                містить високий рівень протеїну, вітамінів і мікроелементів.</p>
                            <p class="text-content pink"> Дія SPERMATEK® спрямована на:</p>
                        </div>
                        <div class="special-ration-item-wrap flex flex-wrap">
                            <div class="special-ration-item">
                                <div class="special-ration-item-img_wrap">
                                    <img class="special-ration-img" src="../assets/img/special-img-1.svg" alt="">
                                </div>
                                <div class="special-ration-item-description">
                                    <p>Розвиток придаткових статевих залоз</p>
                                </div>
                            </div>
                            <div class="special-ration-item">
                                <div class="special-ration-item-img_wrap">
                                    <img class="special-ration-img" src="../assets/img/special-img-2.svg" alt="">
                                </div>
                                <div class="special-ration-item-description">
                                    <p>Посилення статевих рефлексів</p>
                                </div>
                            </div>
                            <div class="special-ration-item">
                                <div class="special-ration-item-img_wrap">
                                    <img class="special-ration-img" src="../assets/img/special-img-3.svg" alt="">
                                </div>
                                <div class="special-ration-item-description">
                                    <p>Збільшення об’єму еякуляту та концентрації сперміїв</p>
                                </div>
                            </div>
                            <div class="special-ration-item">
                                <div class="special-ration-item-img_wrap">
                                    <img class="special-ration-img" src="../assets/img/special-img-4.svg" alt="">
                                </div>
                                <div class="special-ration-item-description">
                                    <p>Довготривале використання та здоров’я самців</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
    export default {
        name: "ration"
    }
</script>

<style scoped>
    .ration-title {
        max-width: 610px;
    }
    .ration-text {
        flex: 0 1 38%;
        margin-right: 2%;
    }
    .ration-text ul {
        @apply my-5
    }
    .ration-text ul li {
        @apply pl-10 mb-4;
        position: relative;
    }

    .ration-text ul li:before {
        content: '';
        position: absolute;
        left: 0;
        top: calc(50% - 3px);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #D8267E;
    }

    .ration-img-wrap {
        @apply flex items-start;
        flex: 0 1 60%;
        position: relative;
    }

    .ration-img {
        max-width: 80% !important;
    }

    .speech-block {
        position: absolute;
        right: 0;
        top: -10%;
    }

    p.speech {
        background-color: #142F5E;
        padding: 20px;
        max-width: 250px;
        line-height: 26px;
        color: white;
        font-size: 18px;
        position: relative;
        text-align: left;
    }

    p.speech:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 10%;
        top: 100%;
        border: 15px solid;
        border-color: #142F5E transparent transparent #142F5E;
    }

    .special-ration {
        position: relative;
    }

    .special-ration:before {
        content: '';
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0, #142F5E 0%, rgba(20,47,94,0) 100%);
        /*opacity: 5% !important;*/
    }

    .special-ration-title-block {
        margin-bottom: 60px;
        max-width: 830px;
    }

    .special-ration-title-block h2 {
        margin-bottom: 20px;
    }

    .special-ration-title-block .text-content.pink {
        color: #D8267E;
    }

    .special-ration-item-wrap {
        @apply flex flex-wrap justify-center;
        margin: -15px;
    }

    .special-ration-item {
        @apply p-5;
        display: flex;
        flex: 0 1 calc((100%/4) - 30px);
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 15px;
        text-align: center;
        background-color: #FFFFFF;
        border-radius: 10px;
        min-height: 270px;
        min-width: 270px;
        z-index: 3;
    }

    .special-ration-item-img_wrap {
        @apply flex justify-center items-center mb-5;
        height: 50%;
    }

    .special-ration-item-description {
        @apply flex flex-col justify-start items-center;
        max-width: 220px;
    }
    .clients-item-description h5 {
        @apply mb-2;
        font-size: 16px;
        font-family: 'Roboto';
        font-weight: bold;

    }

    @media (max-width: 1250px) {
        /*.special-ration-item-wrap {*/
        /*    @apply flex flex-wrap justify-center;*/
        /*    margin: -15px;*/
        /*    width: 80%;*/
        /*}*/

        .special-ration-item {
            /*flex: 0 1 calc((100% / 2) - 30px);*/
            max-width: 270px;
        }
    }

    @media (max-width:1024px) {
        .ration-wrap {
            padding-top: 120px!important;
        }
    }

    @media (max-width: 950px) {
        .ration-text {
            flex: 0 1 50%;
            margin-right: 0;
        }

        .ration-img-wrap {
            flex: 0 1 50%;
        }

        .ration-img {
            max-width: 100% !important;
        }

        .speech-block {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    @media (max-width: 768px) {
        .speech-block {
            position: absolute;
            right: 0;
            top: 10%;
        }
        .block-wrap {
            padding-top: 60px!important;
        }
    }

    @media (max-width: 640px) {
        .ration-description {
            flex-direction: column;
            align-items: center;
        }

        .ration-text {
            margin-right: 0;
            margin-bottom: 1.25rem;
        }

        .ration-img-wrap {
            width: 90%;
            align-items: flex-end;
        }

        .speech-block {
            position: relative;
            margin-bottom: 50px;
        }

        p.speech {
            line-height: 26px;
            font-size: 16px;
        }

        .ration-wrap.block-wrap {
            padding-bottom: 0;
        }

        .special-ration-item-wrap {
            margin: -2.5%;
        }

        /*.special-ration-item {*/
        /*    flex: 0 1 calc((100% / 2) - 5%);*/
        /*    margin: 2.5%;*/
        /*    padding: 2.5%;*/
        /*}*/

        /*.special-ration-item-img_wrap {*/
        /*    margin-bottom: 0;*/
        /*}*/
        /*.special-ration-item-wrap {*/
        /*    margin: -2.5%;*/
        /*}*/

        /*.special-ration-item {*/
        /*    flex: 0 1 calc(100% - 5%);*/
        /*    margin: 2.5%;*/
        /*    padding: 2.5%;*/
        /*}*/
    }

    .ration-img-drop {
        @apply absolute;
        z-index: -1;
        animation: anim 3s linear infinite;
    }

    .ration-img-drop:nth-child(1) {
        top: 15%;
        left: 55%;
    }

    .ration-img-drop:nth-child(2) {
        top: 30%;
        left: 65%;
    }

    .ration-img-drop:nth-child(3) {
        top: 55%;
        left: 30%;
    }

    .ration-img-drop:nth-child(4) {
        top: 50%;
        right: 2%;
    }
    
    @keyframes anim {
        0% {  transform: translateX(0) rotate(0deg);}
        50% {  transform: translateX(-15px) rotate(45deg);}
        /*40% {  transform: translateX(-15px) rotate(90deg);}*/
        /*60% {  transform: translateX(-15px) rotate(90deg);}*/
        /*80% {  transform: translateX(-15px) rotate(45deg);}*/
        100% {  transform: translateX(0) rotate(0deg);}
    }
</style>