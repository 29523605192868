<template>
    <div class="footer-wrap">
        <div class="centered-content-container flex items-center flex-wrap justify-center sm:justify-between h-full">
            <div class="footer-wrap-img-block flex items-center">
                <div class="logo lg:mr-10"></div>
                <div class="tell-block">
                    <a href="tel:+38 067 231 70 37" class="call block items-start">
                        0 800 503 112
                    </a>
                    <span class="tell-text-bottom">Дзвінки безкоштовні</span>
                </div>
                <a href="https://www.tekro.ua/" target="_blank">
                    <div class="tekro">
                    </div>
                </a>
            </div>
            <ul class="menu-items flex">
                <li class="menu-item dropdown" @click="scrollTo('#about')">
                    Продукт
                </li>
                <li class="menu-item dropdown" @click="scrollTo('#advantage')">
                    Чому ми?
                </li>
                <li class="menu-item" @click="scrollTo('#action')">
                    Як працює?
                </li>
                <li class="menu-item" @click="scrollTo('#reviews')">
                    Відгуки
                </li>
            </ul>
            <div class="tell-block lg">
                <a href="tel:+38 067 231 70 37" class="call lg block items-start">
                    0 800 503 112
                </a>
                <span class="tell-text-bottom">Дзвінки безкоштовні</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "footer-block",
        methods: {
            scrollTo (hash) {
                const top = document.querySelector(hash).offsetTop - 80
                window.scrollTo({ top: top, behavior: 'smooth' });
            }
        }
    }
</script>

<style scoped>
    .footer-wrap {
        @apply w-full flex justify-center;
        height: 80px;
        font-family: 'Roboto';
        color: rgba(20, 47, 94, 0.7);
        font-size: 18px;
        background-color: white;
        top: 0;
        left: 0;
        z-index: 5;
    }

    .tekro {
        width: 80px;
        height: 80px;
        background: url("../assets/img/tekro.png") center/contain no-repeat;
        cursor: pointer;
    }
    .tell-text-bottom {
        font-size: 14px;
        color: #d8267e;
        margin-left: 26px;
    }

    .footer-wrap .call::before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url("../assets/img/call.svg") center/contain no-repeat;
        margin-right: 14px;
    }
    .footer-wrap .call::after {
        content: '*';
        position: absolute;
        top: -10px;
        right: -10px;
        color: #d8267e;
        display: inline-block;
    }

    .footer-wrap .call {
        position: relative;
        line-height: 18px;
    }
    .footer-wrap .menu-items .menu-item {
        font-weight: 400;
        margin-right: 60px;
        cursor: pointer;
    }
    .footer-wrap .menu-items .menu-item:last-child {
        margin-right: 0;
    }
    .footer-wrap .menu-items {
        margin-right: 100px;
        margin-left:15px;
    }

    .footer-wrap-img-block .tell-block {
        display: none;
    }

    .footer-wrap .menu-items .menu-item:hover,
    .footer-wrap .call:hover {
        @apply text-pink
    }

    .tell-block.lg {
        display: block;
    }

    @media (max-width: 1240px) {
        .footer-wrap .menu-items {
            margin-right: 0;
            margin-left: 0;
            justify-content: space-between;
        }
    }

    /*@media (max-width: 1024px) {*/
    /*    .footer-wrap .menu-items {*/
    /*        margin-right: 0;*/
    /*        margin-left: 0;*/
    /*    }*/
    /*}*/

    @media (max-width: 1024px) {
        .footer-wrap {
            height: auto;
        }

        .footer-wrap-img-block .tell-block {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .tell-block.lg {
            display: none;
        }

        .footer-wrap-img-block .logo {
            margin-right: 0;
        }

        .footer-wrap-img-block,
        .footer-wrap .menu-items {
            width: 100%;
        }

        .footer-wrap-img-block {
            @apply flex justify-between
        }

        .footer-wrap .menu-items {
            @apply py-5;
            display: flex;
            justify-content: center;
            width: 100%;
            /*margin-left: 190px;*/
        }


        .footer-wrap-img-block {
            order: 2;
        }

        .menu-items {
            order: 1;
        }
    }

    @media(max-width: 640px) {
        .footer-wrap .menu-items {
            justify-content: space-between;
        }

        .footer-wrap .menu-items .menu-item {
            margin-right: 0;
        }

        .tekro {
            width: 40px;
            height: 40px;
        }
        .footer-wrap-img-block {
            margin-bottom: 1.25rem;
        }

        .logo {
            width: 100px;
            height: 23px;
        }

        a {
            font-size: 16px;
        }

        .tell-text-bottom {
            font-size: 12px;
            color: #d8267e;
            margin-left: 26px;
        }
    }
</style>