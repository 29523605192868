<template>
  <div id="app" class="app">
    <Header/>
    <About/>
    <Ration/>
    <Potential/>
    <Advantage/>
    <Reviews/>
    <Form/>
    <Footer/>
  </div>
</template>

<script>
  import Header from "./components/header"
  import About from "./components/about"
  import Ration from "./components/ration"
  import Potential from "./components/potential"
  import Advantage from "./components/action"
  import Reviews from "./components/reviews-slider"
  import Form from "./components/form"
  import Footer from "./components/footer"

export default {
  name: 'App',
  components: {
    Header,
    About,
    Ration,
    Potential,
    Advantage,
    Reviews,
    Form,
    Footer
  }
}
</script>

<style>
  html {
    font-family: Helvetica, sans-serif;
    color: #323232;
  }

  body {
    /*overflow-x: hidden;*/
    font-family: 'Roboto';
    color: #696F71;
    scroll-behavior: smooth;

  }

  body.stop-scrolling {
    height: 100%;
    overflow: hidden;
  }

  .app p,
  .app li {
    font-family: 'Roboto';
    line-height: 30px;
    font-size: 18px;
  }



  .centered-content-container {
    width: 95%;
    max-width: 1170px;
  }

  .app {
    position: relative;
    overflow: hidden;
  }

  .app h1 {
    @apply mb-5;
    font-size: 81px;
    font-family: 'Roboto';
    font-weight: 900;
    letter-spacing: 0;
    line-height: 95px;
    text-align: left;
    color: #142F5E;
  }

  .app h3 {
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 36px;
    color: #D8267E;
  }
  .app h2 {
    font-family: 'Roboto';
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 42px;
    color: #142F5E;
    margin-bottom: 60px;
  }

  .app h4 {
    font-family: 'Roboto';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 30px;
    font-style: italic;
    color: #142F5E;
  }

  .logo {
    width: 133px;
    height: 33px;
    background: url("./assets/logo.png") center/contain no-repeat;
  }

  .block-wrap {
    padding: 120px 0;
  }

  .slider-wrap .reviews-slider .slick-list {
    display: flex!important;
    flex-direction: column;
  }

  .slider-wrap .reviews-slider .slick-list div {
    height: 100%;
  }

  .button {
    @apply flex items-center justify-center;
    height: 60px;
    padding: 0 30px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1px;
    background-color: #D8267E;
    box-shadow: 0 20px 40px 0 rgba(216,38,126,0.3);
    color: white;
    cursor: pointer;
  }

  .button:hover {
    color: rgba(20, 47, 94, 0.7);
  }

  .special-ration:before,
  .reviews:before {
    opacity: .05 !important;
  }

  @media(max-width: 768px) {
    .block-wrap  h1 {
      font-size: 40px;
      text-align: center;
    }


  }


  @media(max-width: 1024px) {
    .block-wrap {
      padding: 60px 0;
    }
  }

  @media(max-width: 640px) {
    .app h1 {
      font-size: 61px;
      text-align: center;
    }

    .block-wrap  h1 {
      font-size: 30px;
      text-align: center;
    }

    .app h2 {
      font-size: 28px;
      margin-bottom: 30px;
    }

    .app h4 {
      font-size: 16px;
      line-height: 28px;
    }

    .app p,
    .app li {
      line-height: 28px;
      font-size: 16px;
    }

    .block-wrap {
      padding: 40px 0;
    }

    .header-wrap .menu-items .menu-item:hover,
    .footer-wrap .menu-items .menu-item:hover {
      @apply text-pink
    }

    .button {
      height: 60px;
      padding: 0 30px;
      border-radius: 30px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
    }
  }

  /*.bitrix {*/
  /*  background-color: #142f5e!important;*/
  /*}*/

  /*.bitrix .crm-webform-iframe {*/
  /*  background-color: #142f5e!important;*/
  /*}*/
</style>
