<template>
    <div class="header-wrap fixed">
        <nav class="centered-content-container flex items-center justify-between h-full">
            <div class="logo" @click="scrollTo('#form')"></div>
            <ul class="menu-items hidden lg:flex">
                <li class="menu-item dropdown" @click="scrollTo('#about')">
                    <a>Продукт</a>
                </li>
                <li class="menu-item dropdown" @click="scrollTo('#advantage')">
                    <a>Чому ми?</a>
                </li>
                <li class="menu-item" @click="scrollTo('#action')">
                    <a>Як працює?</a>
                </li>
                <li class="menu-item" @click="scrollTo('#reviews')">
                    <a>Відгуки</a>
                </li>
            </ul>
            <div class="hidden lg:block">
                <a href="tel:+38 0 800 503 112" class="call block items-start">
                    0 800 503 112
                </a>
                <span class="tell-text-bottom">Дзвінки безкоштовні</span>
            </div>
            <div class="burger_block flex lg:hidden">
                <button class="burger" @click="openMobileMenu" :class="{'open': burgerOpen}"></button>
            </div>
        </nav>
        <div class="header-mobile fixed lg:hidden" :class="{'active': burgerOpen}">
            <div class="header-mobile-wrapper" v-if="burgerOpen" :class="{'active': mobileMenuWrap}">
                <nav class="block-nav">
                    <div class="logo" @click="openMobileMenu('#form')"></div>
                    <ul class="block-nav-wrapper">
                        <li @click="openMobileMenu('#about')" class="block-nav-item">
                            <a>Продукт</a>
                        </li>
                        <li @click="openMobileMenu('#advantage')" class="block-nav-item">
                            <a>Чому ми?</a>
                        </li>
                        <li @click="openMobileMenu('#action')" class="block-nav-item">
                            <a>Як працює?</a>
                        </li>
                        <li @click="openMobileMenu('#reviews')" class="block-nav-item">
                            <a>Відгуки</a>
                        </li>
                    </ul>
                </nav>
                <div class="header-mobile-block-contact">
                    <a href="tel:+38 0 800 503 112" class="header_contact call block items-start">
                        0 800 503 112
                    </a>
                    <span class="tell-text-bottom">Дзвінки безкоштовні</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "header-block",
        data() {
            return {
                mobileMenuWrap: false,
                burgerOpen: false
            }
        },
        methods: {
            openMobileMenu(hash) {
                this.burgerOpen = !this.burgerOpen
                setTimeout(() => this.mobileMenuWrap = !this.mobileMenuWrap,500)
                let top = null
                if (hash ==='#action') {
                    top = (document.querySelector(hash).offsetTop + document.querySelector('#action-block').offsetTop + document.querySelector('#action-mob').offsetTop)  - 80
                } else {
                    top = document.querySelector(hash).offsetTop - 80
                }
                window.scrollTo({ top: top, behavior: 'smooth' });
            },
            scrollTo (hash) {
                let top = null
                if (hash ==='#action') {
                    top = (document.querySelector(hash).offsetTop + document.querySelector('#action-block').offsetTop)  - 80
                } else {
                    top = document.querySelector(hash).offsetTop - 80
                }
                window.scrollTo({ top: top, behavior: 'smooth' });
            }
        }
    }
</script>

<style scoped>
    .header-wrap {
        @apply w-full flex justify-center;
        height: 80px;
        font-family: 'Roboto';
        color: rgba(20, 47, 94, 0.7);
        font-size: 18px;
        background-color: white;
        top: 0;
        left: 0;
        z-index: 5;
    }
    .tell-text-bottom {
        font-size: 14px;
        color: #d8267e;
        margin-left: 25px;
    }

    .logo {
        cursor: pointer;
    }

    .header-wrap .call::before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url("../assets/img/call.svg") center/contain no-repeat;
        margin-right: 14px;
    }

    .header-wrap .call::after {
        content: '*';
        position: absolute;
        top: -10px;
        right: 0;
        color: #d8267e;
        display: inline-block;
        /*width: 14px;*/
        /*height: 14px;*/
    }
    .header-wrap .call {
        position: relative;
        line-height: 18px;
    }
    .header-wrap .menu-items .menu-item {
        font-weight: 400;
        margin-right: 60px;
        cursor: pointer;
    }

    .header-wrap .menu-items .menu-item:hover,
    .header-wrap .call:hover {
        @apply text-pink
    }

    .header-wrap .menu-items .menu-item:last-child {
        margin-right: 0;
    }
    .header-wrap .menu-items {
        margin-right: 70px;
        margin-left: 190px;
    }

    .burger_block {
        align-items: center;
        z-index: 10;
        outline: none;
        /*margin-right: 2.5%;*/
    }

    .burger_block .burger {
        width: 40px;
        border-bottom: 3px solid #142F5E;
        transition: all 0.5s;
        margin-top: 0;
        outline: none;
    }

    .burger_block .burger:before {
        content: "";
        display: flex;
        width: 100%;
        border-bottom: 3px solid #142F5E;
        transition: transform 0.5s ease-in-out;
        margin-bottom: 5px;
    }
    .burger_block .burger:after {
        content: "";
        display: flex;
        border-bottom: 3px solid #142F5E;
        width: 100%;
        margin-bottom: 5px;
        transition: transform 0.5s ease-in-out;
    }

    .burger_block .burger.open {
        margin-top: 12px;
        border-bottom: 2px solid transparent;
    }
    .burger_block .burger.open:before {
        transform: rotate(-405deg) translateY(1px) translateX(-1px);
    }
    .burger_block .burger.open:after {
        transform: rotate(405deg) translateY(-4px) translateX(-5px);
    }


    .header-mobile {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 8;
        background-color: rgba(85, 92, 108, 0.9);
        transition: linear 0.5s;
        opacity: 0;
        pointer-events: none;
    }

    .header-mobile.active {
        opacity: 1;
        pointer-events: visible;
    }

    .header-mobile-wrapper {
        position: fixed;
        top: -100%;
        left: 0;
        z-index: 9;
        background-color: #142F5E;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 80%;
        /*height: 50%;*/
        /*min-height:100vh;*/

        transition: linear 0.5s;
    }

    .header-mobile-wrapper.active {
        top: 0;
    }

    .block-nav-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        border-top: 2px solid #FFFFFF;
    }

    .block-nav {
        width: 100%;
    }

    .block-nav .logo {
        @apply my-6 ml-10;
        filter: brightness(2000%);
    }


    .block-nav-item,
    .header-mobile-block-contact {
        @apply py-5 pl-10;
        color: white;
        font-size:21px;
    }

    .block-nav-item {
        border-bottom: 2px solid #FFFFFF;
    }

    .header-mobile-wrapper a,
    .header-mobile-block-contact a{
        color: #ffffff;
        line-height: 1.5!important;
    }

    .header-mobile-wrapper a:hover{
        color: indianred;
    }

    /*.header-mobile-block-contact {*/
    /*  @apply my-10;*/
    /*  font-size: 14px;*/
    /*}*/
    .header-mobile .call {
        position: relative;
        line-height: 0.5!important;
    }

    .header-mobile .call::before {
        width: 20px;
        height: 20px;
    }

    .header-mobile .call::after {
        content: '*';
        position: absolute;
        top: -5px;
        right: -15px;
        color: #d8267e;
        display: inline-block;
        /*width: 14px;*/
        /*height: 14px;*/
    }


    @media(max-width: 1140px) {
        .header-wrap .menu-items {
            margin-right: 0;
            margin-left: 0;
        }
    }
    @media(max-width: 640px) {
        .header-mobile-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .burger_block .burger.open:before {
            border-bottom: 3px solid #ffffff;
        }
        .burger_block .burger.open:after {
            border-bottom: 3px solid #ffffff;
        }

        .block-nav-item,
        .header-mobile-block-contact {
            padding-left: 1.25rem;
        }

        .header-mobile-wrapper .logo {
            margin-left: 1.25rem;
        }
    }

    @media(max-height: 500px) {
        .header-mobile-wrapper .block-nav-item {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        .header-mobile .call[data-v-29e8c3c6]::after {
            top: 0;
        }

        .header-mobile-block-contact {
            padding-top: 0.7rem;
            padding-bottom: 0.7rem;
            line-height: 1;
        }

    }

    @media(max-height: 321px) {
        .block-nav .logo {
            margin-top: 1.1rem;
            margin-bottom: 1.1rem;
        }

        .header-mobile-wrapper .block-nav-item {
            padding-top: 0.4rem;
            padding-bottom: 0.4rem;
        }

        .burger.open {
            margin-top: 0!important;
        }

        .burger_block .burger {
            width: 30px;
        }
    }
</style>