<template>
    <div class="about w-full" id="about">
        <div class="centered-content-container mx-auto">
            <div class="about-wrap flex w-full">
                <div class="about-description-wrap flex flex-col items-center justify-center sm:items-start">
                    <a href="https://www.tekro.ua/" target="_blank">
                        <div class="about-tekro">
                        </div>
                    </a>
                    <div class="about-description">
                        <h1>Spermatek</h1>
                        <h3><span>1</span>-ий крок у відтворенні свинопоголів’я!</h3>
                        <p>Інноваційний підсилювач<br> репродуктивної здатності кнурів<br> для відмінного відтворення стада.</p>
                    </div>
                    <div class="button" @click="scrollTo('#form')">
                        Замовити дзвінок
                    </div>
                    <span class="span-top text-center sm:text-left">Замовляйте SPERMATEK® та отримайте комплексний аудит системи відтворення у господарстві безкоштовно*!</span>
                    <span class="text-center sm:text-left text-xs sm:text-sm">* деталі дізнавайтеся у менеджера-консультанта!</span>
                </div>
                <div class="about-img_wrap">
                    <img class="about-img" src="../assets/img/pig.webp" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "about",
        methods: {
            scrollTo (hash) {
                const top = document.querySelector(hash).offsetTop - 80
                window.scrollTo({ top: top, behavior: 'smooth' });
            }
        }
    }
</script>

<style scoped>
    .about {
        /*background-color: #DAEEF5;*/
        margin-top: 80px;
        background-image: url("../assets/img/about-bg.webp");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .about-wrap {
        position: relative;
        padding-top: 90px;
        padding-bottom: 70px;
    }

    .about-tekro {
        width: 80px;
        height: 80px;
        background: url("../assets/img/tekro.png") center/contain no-repeat;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
    }

    /*.about-wrap:after {*/
    /*    content: '';*/
    /*    position: absolute;*/
    /*    width: 80px;*/
    /*    height: 80px;*/
    /*    top: 0;*/
    /*    right: 0;*/
    /*    background: url("../assets/img/tekro.webp") center/cover no-repeat;*/
    /*}*/

    .about-description {
        max-width: 500px;
    }

    .about-description p {
        font-family: 'Roboto';
        font-size: 24px;
        font-weight: normal;
        line-height: 36px;
        color: #696F71;
        margin-bottom: 40px;
    }

    .button {
    @apply mb-8
    }

    .about-description-wrap span.span-top {
        max-width: 315px;
    }

    h3 span {
        font-size: 50px;
        font-weight: bold;
    }

    .about-img_wrap {
        position: absolute;
        right: 5%;
        bottom: -80px;
    }

    @media(max-width: 1024px) {
        .about-img_wrap {
            width: 45%;
            right: 0;
            /*bottom: -80px;*/
        }
    }

    @media(max-width: 768px) {
        .about-img_wrap {
            width: 45%;
            right: 0;
            bottom: -60px;
        }
    }

    @media(max-width: 640px) {
        .about {
            background-size: cover;
        }
        .about-wrap {
            flex-direction: column;
            margin-bottom: 60px;
            align-items: center;
        }

        .about-description {
            text-align: center;
        }

        .about-description p {
            font-size: 20px;
            line-height: 32px;
            right: 0;
        }

        .about-img_wrap {
            position:relative;
            width: 75%;
            margin-top: 10%;
            margin-bottom: -25%;
            right: 0;
            bottom: 0;
            /*top: 10%;*/
        }
    }

    @media(max-width: 500px) {
        .about-img_wrap {
            margin-bottom: -35%;
            width: 85%;
        }
    }

    @media(max-width: 380px) {
        .about-img_wrap {
            margin-bottom: -35%;
            width: 95%;
            /*width: 100%;*/
        }
    }
</style>