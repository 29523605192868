<template>
    <div class="potential w-full">
        <div class="centered-content-container flex flex-col mx-auto relative ">
            <img class="potential-img-drop" src="../assets/img/potential-center.svg" alt="">
            <img class="potential-img-drop" src="../assets/img/potential-bottom.svg" alt="">
            <img class="potential-img-drop" src="../assets/img/potential-left.svg" alt="">
            <div class="potential-wrap block-wrap">
                    <div class="potential-item potential-img_wrap">
                        <img class="potential-img" src="../assets/img/potential.webp" alt="">
                    </div>
                    <div class="potential-item potential-description w-full flex flex-col items-start sm:justify-between">
                        <h2>Розкрийте потенціал кнурів на повну </h2>
                        <p>Додавання SPERMATEK ® до раціону вирішує проблеми, пов’язані з експлуатацією кнурів та дає наступні переваги:</p>
                        <ul>
                            <li>Посилення лібідо та збільшення кількості спермодоз до 30%</li>
                            <li>Збільшення рухливості сперміїв на 17%</li>
                            <li>Збільшення заплідненості на 9,5%</li>
                            <li>Збільшення живих поросят у гнізді на 1 голову</li>
                        </ul>
                        <h4 class="mb-5">Наведено усереднені дані на основі отриманих результатів у господарствах.</h4>
<!--                        <div class="button" @click="scrollTo('#form')">-->
<!--                            Замовити SPERMATEK®-->
<!--                        </div>-->
                        <div class="button" @click="scrollTo('#form')">
                            Замовити дзвінок
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
    export default {
        name: "potential",
        methods: {
            scrollTo (hash) {
                const top = document.querySelector(hash).offsetTop - 80
                window.scrollTo({ top: top, behavior: 'smooth' });
            }
        }
    }
</script>

<style scoped>
    .potential {
        background-color: #142F5E;
        color: #FFFFFF;
    }

    .potential-wrap {
        @apply flex flex-wrap justify-between;
        margin: -15px;
        z-index: 2;
    }

    .potential-item {
        display: flex;
        flex: 0 1 calc((100%/2) - 30px);
        flex-direction: column;
        align-items: flex-start;
        margin: 15px;
    }

    .potential-item h2 {
        color: #FFFFFF;
        max-width: 370px;
    }

    .potential-item h4 {
        @apply text-grey;
        max-width: 370px;
    }

    .potential-item ul {
        @apply my-5
    }
    .potential-item ul li {
        @apply pl-10 mb-4;
        position: relative;
    }

    .potential-item ul li:before {
        content: '';
        position: absolute;
        left: 0;
        top: calc(50% - 3px);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #D8267E;
    }

    .potential-item.potential-description {
    }

    .potential-img_wrap {
        padding-right: 5%;
    }

    .potential-img_wrap img {
        /*max-width: 500px;*/
        width: 100%;
    }

    /*.button {*/
    /*    @apply flex items-center justify-center;*/
    /*    height: 60px;*/
    /*    padding: 0 30px;*/
    /*    border-radius: 30px;*/
    /*    font-size: 18px;*/
    /*    font-weight: 500;*/
    /*    line-height: 1px;*/
    /*    background-color: #D8267E;*/
    /*    box-shadow: 0 20px 40px 0 rgba(216,38,126,0.3);*/
    /*    color: white;*/
    /*}*/

    @media(max-width: 1024px) {
        .potential-wrap
        {
            flex-wrap: wrap;
            justify-content: center;
        }

        .vacancies-demands {
            padding: 5%;
        }

        .vacancies-demands {
            position: relative;
            flex-wrap: wrap;
        }

        .vacancies-demands-img_wrap {
            max-width: 100%;
            height: 100%;
            margin-right: 0;
            position: absolute;
            opacity: 15%;
            top: 0;
            left: 0;
        }

        .vacancies-demands-img_wrap img {
            height: 100%;
        }
    }
    @media(max-width: 950px) {

        .potential-item {
            display: flex;
            flex: 0 1 100%;
        }

        .potential-img_wrap {
            max-width: 50%;
            padding-right: 0;
        }

        /*.potential-img {*/
        /*    height: 300px;*/
        /*}*/

        .potential-description h2,
        .potential-description h4 {
            max-width: 100%;
        }

        .vacancies-demands-description li {
            font-size: 16px;
        }

        .vacancies-demands-description li:not(:last-child){
            margin-bottom: 10px;
        }

        .vacancies-demands-description h2 {
            text-align: center;
        }

        .button {
            @apply mx-auto
        }
    }

    @media(max-width: 640px) {

        .potential-img_wrap {
            max-width: 100%;
            padding-right: 0;
        }
    }


    .potential-img-drop {
        @apply absolute;
        z-index: 0;
        animation: anim 3s linear infinite;
    }

    .potential-img-drop:nth-child(1) {
        top: 30%;
        left: 39%;
    }

    .potential-img-drop:nth-child(2) {
        bottom: -5%;
        left: 30%;
    }

    .potential-img-drop:nth-child(3) {
        bottom: 10%;
        left: -5%;
        z-index: 3;
    }


    @keyframes anim {
        0% {  transform: translateX(0) rotate(0deg);}
        50% {  transform: translateX(-15px) rotate(45deg);}
        /*40% {  transform: translateX(-15px) rotate(90deg);}*/
        /*60% {  transform: translateX(-15px) rotate(90deg);}*/
        /*80% {  transform: translateX(-15px) rotate(45deg);}*/
        100% {  transform: translateX(0) rotate(0deg);}
    }


    @media(max-width: 950px) {

        .potential-img-drop:nth-child(1) {
            top: 10%;
            left: 69%;
        }

        .potential-img-drop:nth-child(2) {
            display: none;
        }

        .potential-img-drop:nth-child(3) {
            width: 15%;
            bottom: 18%;
            left: 80%;
            z-index: 1;
        }
    }

    @media(max-width: 768px) {

        .potential-img-drop:nth-child(3) {
            width: 20%;
            bottom: 15%;
            left: 80%;
            z-index: 1;
        }
    }
</style>